import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import React from "react";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import TabPanel from "../common/TabPanel";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";

const BillingPanel = ({ employer, ...props }) => {
    return (
        <TabPanel {...props}>
            <Typography variant="h4" color="primary" paragraph>
                Current Plan
            </Typography>
            {employer.premium ? (
                <Typography variant="body" color="text" paragraph>
                    Subscription active: You can add unlimited premium adverts, access the candidate database and job advert analytics, and benefit from bespoke social media posts. Email us at any time about how we can support your hiring further.
                </Typography>
            ) : (
                <React.Fragment>
                    <Typography variant="body" color="text" paragraph sx={{marginBottom:'4px'}}>
                        Free/PAYG plan: Premium adverts see a ~1.5-2x increase in reach through social media posts, our candidate matching algorithm, and being highlighted across our website. 
                    </Typography>
                    <Typography variant="body" color="text" paragraph >
                        To make an advert premium navigate to the Jobs tab, select an advert and click 'Buy', or email us at advertise@spacecareers.uk for a free trial of the unlimited premium service which includes access to our database of job seekers and unlimited premium job adverts.
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<ShoppingCartRoundedIcon />}
                        onClick={() => {
                            axiosInstance
                                .post(`/api/stripe/create-checkout-session/subscription`)
                                .then((response) => {
                                    window.location = response.data.url;
                                });
                        }}
                    >
                        Upgrade to a subscription
                    </Button>
                </React.Fragment>
            )}
            <Divider sx={{ paddingTop: "20px", marginBottom: "10px" }} />
            <Typography variant="h4" color="primary" paragraph>
                Billing history
            </Typography>
            <Button
                variant="contained"
                onClick={() => {
                    axiosInstance
                        .post(`/api/stripe/create-customer-portal-session/`)
                        .then((response) => {
                            window.location = response.data.url;
                        });
                }}
            >
                View and manage billing info
            </Button>
        </TabPanel>
    );
};

export default BillingPanel;
