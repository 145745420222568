import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const TierBox = ({ tier, children, price, color = undefined }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
                border: `2px ${color || theme.palette.primary.main} solid`,
            }}
        >
            <Box
                sx={{
                    backgroundColor: color || theme.palette.primary.main,
                    width: "calc(100% - 10px)",
                    padding: "5px",
                    textAlign: "center",
                    overflow: "hidden",
                    borderRadius: "6px 6px 0px 0px",
                    marginBottom: "auto",
                }}
                mb={2}
            >
                <Typography variant="body" color="secondary" textAlign="center">
                    {tier}
                </Typography>
            </Box>
            {children}

            <Typography variant="h6" color="text" textAlign="center" mt={2} mb={2}>
                {price}
            </Typography>
        </Box>
    );
};

const IconGrid = () => {
    const theme = useTheme();
    return (
        <Grid container p={3} spacing={6} display={{ xs: "none", sm: "flex" }}>
            <Grid item xs={6} md={4} align="center">
                <Stack sx={{ height: "100%" }}>
                    <img
                        src="/space-suit_2.png"
                        style={{
                            maxWidth: "70px",
                            display: "block",

                            margin: "auto",
                            marginBottom: 0,
                            alignSelf: "center",
                        }}
                    />
                    <Typography variant="h6" color="primary" paragraph mt={1}>
                        Space Specific
                    </Typography>
                    <Typography
                        color="text"
                        variant="body"
                        sx={{ minHeight: { md: "1em", sm: "2.75em", xs: "3.75em" } }}
                    >
                        We advertise only for space-related organisations
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={6} md={4} align="center">
                <Stack sx={{ height: "100%" }}>
                    <img
                        src="/UK.png"
                        style={{
                            maxWidth: "70px",
                            display: "block",

                            margin: "auto",
                            marginBottom: 0,
                            alignSelf: "center",
                        }}
                    />
                    <Typography variant="h6" color="primary" paragraph mt={1}>
                        UK Focused
                    </Typography>
                    <Typography
                        color="text"
                        variant="body"
                        sx={{ minHeight: { md: "1em", sm: "2.75em", xs: "3.75em" } }}
                    >
                        Aimed at candidates in the UK but including jobs abroad
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={6} md={4} align="center">
                <Stack sx={{ height: "100%" }}>
                    <img
                        src="/worldwide2.png"
                        style={{
                            maxWidth: "70px",
                            display: "block",

                            margin: "auto",
                            marginBottom: 0,
                            alignSelf: "center",
                        }}
                    />
                    <Typography variant="h6" color="primary" paragraph mt={1}>
                        Any Jobs
                    </Typography>
                    <Typography
                        color="text"
                        variant="body"
                        sx={{ minHeight: { md: "1em", sm: "2.75em", xs: "3.75em" } }}
                    >
                        Space is more than science and engineering!
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={6} md={4} align="center">
                <Stack sx={{ height: "100%" }}>
                    <Box
                        component="img"
                        src="/ukseds-logo-dark.png"
                        sx={{
                            maxWidth: { sm: "180px", xs: "140px" },
                            display: "block",

                            margin: "auto",
                            marginBottom: 0,
                            alignSelf: "center",
                        }}
                        pt={{ sm: 0, xs: "2rem" }}
                    ></Box>
                    <Typography variant="h6" color="primary" paragraph mt={1}>
                        Connected with UKSEDS
                    </Typography>
                    <Typography
                        color="text"
                        variant="body"
                        sx={{ minHeight: { md: "1em", sm: "2.75em", xs: "3.75em" } }}
                    >
                        Promoted to a diverse network of enthusiastic students
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={6} md={4} align="center">
                <Stack sx={{ height: "100%" }}>
                    <img
                        src="/generous.png"
                        style={{
                            maxWidth: "70px",
                            display: "block",

                            margin: "auto",
                            marginBottom: 0,
                            alignSelf: "center",
                        }}
                    />
                    <Typography variant="h6" color="primary" paragraph mt={1}>
                        Not-For-Profit
                    </Typography>
                    <Typography
                        color="text"
                        variant="body"
                        sx={{ minHeight: { md: "1em", sm: "2.75em", xs: "3.75em" } }}
                    >
                        Revenue is invested back into improving our services
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={6} md={4} align="center">
                <Stack sx={{ height: "100%" }}>
                    <img
                        src="/graph_with_arrow.png"
                        style={{
                            maxWidth: "70px",
                            display: "block",

                            margin: "auto",
                            marginBottom: 0,
                            alignSelf: "center",
                        }}
                    />
                    <Typography variant="h6" color="primary" paragraph mt={1}>
                        Well Established
                    </Typography>
                    <Typography
                        color="text"
                        variant="body"
                        sx={{ minHeight: { md: "1em", sm: "2.75em", xs: "3.75em" } }}
                    >
                        The go-to-platform for space opportunities
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    );
};

const IconGridSmall = () => {
    const theme = useTheme();
    return (
        <Grid container p={3} spacing={2} display={{ xs: "flex", sm: "none" }}>
            <Grid item xs={6} md={4} align="center" sx={{ alignSelf: "flex-end" }}>
                <img
                    src="/space-suit_2.png"
                    style={{
                        maxWidth: "70px",
                        display: "block",

                        margin: "auto",
                        marginBottom: 0,
                        alignSelf: "center",
                    }}
                />
            </Grid>
            <Grid item xs={6} md={4} align="center" sx={{ alignSelf: "flex-end" }}>
                <img
                    src="/UK.png"
                    style={{
                        maxWidth: "70px",
                        display: "block",

                        margin: "auto",
                        marginBottom: 0,
                        alignSelf: "center",
                    }}
                />
            </Grid>
            <Grid item xs={6} md={4} align="center">
                <Typography variant="h6" color="primary" paragraph mt={1}>
                    Space Specific
                </Typography>
                <Typography
                    color="text"
                    variant="body"
                    sx={{ minHeight: { md: "1em", sm: "2.75em", xs: "3.75em" } }}
                >
                    We advertise only for space-related organisations
                </Typography>
            </Grid>
            <Grid item xs={6} md={4} align="center">
                <Typography variant="h6" color="primary" paragraph mt={1}>
                    UK Focused
                </Typography>
                <Typography
                    color="text"
                    variant="body"
                    sx={{ minHeight: { md: "1em", sm: "2.75em", xs: "3.75em" } }}
                >
                    Aimed at candidates in the UK but including jobs abroad
                </Typography>
            </Grid>
            <Grid item xs={6} md={4} align="center" sx={{ alignSelf: "flex-end" }}>
                <img
                    src="/worldwide2.png"
                    style={{
                        maxWidth: "70px",
                        display: "block",

                        margin: "auto",
                        marginBottom: 0,
                        alignSelf: "center",
                    }}
                />
            </Grid>
            <Grid item xs={6} md={4} align="center" sx={{ alignSelf: "flex-end" }}>
                <Box
                    component="img"
                    src="/ukseds-logo-dark.png"
                    sx={{
                        maxWidth: { sm: "180px", xs: "140px" },
                        display: "block",

                        margin: "auto",
                        marginBottom: 0,
                        alignSelf: "center",
                    }}
                    pt={{ sm: 0, xs: "2rem" }}
                ></Box>
            </Grid>
            <Grid item xs={6} md={4} align="center">
                <Typography variant="h6" color="primary" paragraph mt={1}>
                    Any Jobs
                </Typography>
                <Typography
                    color="text"
                    variant="body"
                    sx={{ minHeight: { md: "1em", sm: "2.75em", xs: "3.75em" } }}
                >
                    Space is more than science and engineering!
                </Typography>
            </Grid>
            <Grid item xs={6} md={4} align="center">
                <Typography variant="h6" color="primary" paragraph mt={1}>
                    Connected with UKSEDS
                </Typography>
                <Typography
                    color="text"
                    variant="body"
                    sx={{ minHeight: { md: "1em", sm: "2.75em", xs: "3.75em" } }}
                >
                    Promoted to a diverse network of enthusiastic students
                </Typography>
            </Grid>
            <Grid item xs={6} md={4} align="center" sx={{ alignSelf: "flex-end" }}>
                <img
                    src="/generous.png"
                    style={{
                        maxWidth: "70px",
                        display: "block",

                        margin: "auto",
                        marginBottom: 0,
                        alignSelf: "center",
                    }}
                />
            </Grid>
            <Grid item xs={6} md={4} align="center" sx={{ alignSelf: "flex-end" }}>
                <img
                    src="/graph_with_arrow.png"
                    style={{
                        maxWidth: "70px",
                        display: "block",

                        margin: "auto",
                        marginBottom: 0,
                        alignSelf: "center",
                    }}
                />
            </Grid>
            <Grid item xs={6} md={4} align="center">
                <Typography variant="h6" color="primary" paragraph mt={1}>
                    Not-For-Profit
                </Typography>
                <Typography
                    color="text"
                    variant="body"
                    sx={{ minHeight: { md: "1em", sm: "2.75em", xs: "3.75em" } }}
                >
                    Revenue is invested back into improving our services
                </Typography>
            </Grid>
            <Grid item xs={6} md={4} align="center">
                <Typography variant="h6" color="primary" paragraph mt={1}>
                    Well Established
                </Typography>
                <Typography
                    color="text"
                    variant="body"
                    sx={{ minHeight: { md: "1em", sm: "2.75em", xs: "3.75em" } }}
                >
                    The go-to-platform for space opportunities
                </Typography>
            </Grid>
        </Grid>
    );
};

const Option = ({ option, included }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                width: "calc(100% - 24px)",

                display: "flex",
                flexDirection: "row",
                alignItems: "left",
                justifyContent: "left",
                padding: "2px",
            }}
        >
            <Typography
                variant="body"
                color="text"
                textAlign="center"
                sx={{ padding: "2px", paddingRight: "10px", textAlign: "left" }}
            >
                {option}
            </Typography>
            {included ? (
                <CheckIcon sx={{ alignSelf: "center", color: "green", marginLeft: "auto" }} />
            ) : (
                <CloseIcon
                    sx={{
                        alignSelf: "center",
                        color: theme.palette.text.primary,
                        marginLeft: "auto",
                    }}
                />
            )}
        </Box>
    );
};

const AdvertisePage = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    return (
        <PageContainer maxWidth="md">
            <Paper noPadding>
                <Box px={{ md: "50px", xs: "20px" }}>
                    <Typography variant="h3" paragraph color="text" textAlign="center" pt={3}>
                        Advertise
                    </Typography>
                    <Typography
                        variant="body"
                        paragraph
                        color="text"
                        textAlign="center"
                        sx={{
                            fontSize: "1.26rem",
                            fontStyle: "italic",
                            padding: { md: "20px 80px", xs: "20px 20px" },
                            fontWeight: "600",
                        }}
                    >
                        “The{" "}
                        <Typography color="primary" sx={{ display: "inline", font: "inherit" }}>
                            quality of applicants
                        </Typography>{" "}
                        we get from SpaceCareers.uk is higher than we get from LinkedIn” - Dr Adam
                        White, UK SME
                    </Typography>
                    <Typography variant="body" paragraph color="text" textAlign="center">
                        SpaceCareers.uk is the only site of its kind. It ranks highly in searches
                        and on social media, and with over 600,000 page views a year, we reach
                        hundreds of job seekers every day. As a not-for-profit, we put all our
                        effort into supporting candidates and helping you find the ones who are
                        right for you, and feed everything back into improving our service and
                        access to the space sector.
                    </Typography>
                    <Typography variant="body" paragraph color="text" textAlign="center">
                        Advertising a job is easy. Create your account and gain access to immediate
                        and complete control over your job adverts, as well as access to the pool of
                        candidates and resources to support your recruitment strategies. We now
                        offer both early career and beyond early career advertising!
                    </Typography>
                    <Typography
                        variant="body"
                        paragraph
                        color="text"
                        textAlign="center"
                        sx={{
                            fontSize: "1.26rem",
                            fontStyle: "italic",
                            padding: "20px",
                            fontWeight: "600",
                        }}
                    >
                        “Many of our{" "}
                        <Typography color="primary" sx={{ display: "inline", font: "inherit" }}>
                            strongest applicants
                        </Typography>{" "}
                        came via SpaceCareers.uk, and more than one third of our applications came
                        from women” - UK University
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            sx={{ display: "flex", margin: { xs: "auto", md: "0" } }}
                        >
                            <TierBox tier="Unlimited Basic" price="Free!">
                                <Option option="Basic employer profile" included />
                                <Option option="Employer resources" included />
                                <Option option="Job language analyser" included />
                                <Option option="Job featured on homepage" />
                                <Option option="Social media posts" />
                                <Option option="Job analytics" />
                                <Option option="Candidate matching" />
                                <Option option="Access to candidate profiles and contact" />
                            </TierBox>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            sx={{ display: "flex", margin: { xs: "auto", md: "0" } }}
                        >
                            <TierBox tier="Premium Advert" price="£199/advert">
                                <Option option="Basic employer profile" included />
                                <Option option="Employer resources" included />
                                <Option option="Job language analyser" included />
                                <Option option="Job featured on homepage" included />
                                <Option option="Social media posts" included />
                                <Option option="Job analytics" included />
                                <Option option="Candidate matching" included />
                                <Option option="Access to candidate profiles and contact" />
                            </TierBox>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            sx={{ display: "flex", margin: { xs: "auto", md: "0" } }}
                        >
                            <TierBox tier="Unlimited Premium" price="£499/month*" color="#EA4C82">
                                <Option option="Custom employer profile" included />
                                <Option option="Employer resources" included />
                                <Option option="Job language analyser" included />
                                <Option option="Job featured on homepage" included />
                                <Option option="Social media posts" included />
                                <Option option="Job analytics" included />
                                <Option option="Candidate matching" included />
                                <Option
                                    option="Access to candidate profiles and contact"
                                    included
                                />
                            </TierBox>
                        </Grid>
                    </Grid>
                    <Typography
                        variant="body"
                        paragraph
                        color="text"
                        textAlign="center"
                        pt={1}
                        sx={{ fontWeight: "600" }}
                    >
                        *includes 30 day free trial
                    </Typography>
                    <Typography
                        variant="body"
                        paragraph
                        color="text"
                        textAlign="center"
                        pt={3}
                        pb={3}
                    >
                        If none of these options suit you, get in touch and we would be happy to
                        discuss alternatives.
                    </Typography>
                </Box>
                <Box sx={{ backgroundColor: theme.palette.primary.main, textAlign: "center" }}>
                    <Typography
                        variant="h5"
                        paragraph
                        color="secondary"
                        textAlign="center"
                        pt={4}
                        pb={3}
                    >
                        How it works
                    </Typography>
                    <Grid container sx={{ justifyContent: "center" }} spacing={4}>
                        <Grid item xs={12} md={2} align="center">
                            <img
                                src="/envelope.png"
                                style={{
                                    maxWidth: "70px",
                                    display: "block",
                                    paddingBottom: "10px",
                                }}
                            />
                            <Typography color="secondary" variant="caption">
                                Email us at advertise@spacecareers.uk and we will get you setup
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2} align="center">
                            <img
                                src="/user.png"
                                style={{
                                    maxWidth: "70px",
                                    display: "block",
                                    paddingBottom: "10px",
                                }}
                            />
                            <Typography color="secondary" variant="caption">
                                Login and select your preferred plan
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2} align="center">
                            <img
                                src="/file.png"
                                style={{
                                    maxWidth: "70px",
                                    display: "block",
                                    paddingBottom: "10px",
                                }}
                            />
                            <Typography color="secondary" variant="caption">
                                Upload your job advert
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2} align="center">
                            <img
                                src="/like.png"
                                style={{
                                    maxWidth: "70px",
                                    display: "block",
                                    paddingBottom: "10px",
                                }}
                            />
                            <Typography color="secondary" variant="caption">
                                If it's your first advert, we'll review and publicise it within 24
                                hours
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2} align="center">
                            <img
                                src="/graph.png"
                                style={{
                                    maxWidth: "70px",
                                    display: "block",
                                    paddingBottom: "10px",
                                }}
                            />
                            <Typography color="secondary" variant="caption">
                                See how your ad is performing and contact candidates
                            </Typography>
                        </Grid>
                    </Grid>
                    <Button
                        sx={{
                            width: "200px",
                            margin: "20px auto",
                        }}
                        variant="contained"
                        color="highlight"
                        href="mailto:advertise@spacecareers.uk"
                    >
                        Get in touch
                    </Button>
                </Box>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{ backgroundColor: theme.palette.secondary.main }}
                        textAlign="center"
                    >
                        <Box p={3}>
                            <Typography variant="h5" paragraph color="primary" pt={4}>
                                Search & Contact Candidates
                            </Typography>
                            <img
                                src="/search.png"
                                style={{
                                    maxWidth: "70px",
                                    display: "block",
                                    paddingBottom: "20px",
                                    margin: "auto",
                                }}
                            />
                            <Typography paragraph variant="body2">
                                Job seekers can create profiles outlining their job interests,
                                education, experience, and a short biography
                            </Typography>
                            <Typography paragraph variant="body2">
                                You can search the candidate database and contact candidates who
                                match your need
                            </Typography>
                            <Typography paragraph variant="body2">
                                The profiles are anonymous and you can only contact candidates who
                                have selected that they are happy to be contacted
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{ backgroundColor: theme.palette.text.primary }}
                        textAlign="center"
                    >
                        <Box p={3}>
                            <Typography
                                variant="h5"
                                paragraph
                                color="secondary"
                                textAlign="center"
                                pt={4}
                            >
                                Automatic Matching
                            </Typography>
                            <img
                                src="/partner.png"
                                style={{
                                    maxWidth: "70px",
                                    display: "block",
                                    paddingBottom: "20px",
                                    margin: "auto",
                                }}
                            />
                            <Typography paragraph color="secondary" variant="body2">
                                Our system automatically matches the details of your jobs, such as
                                the job type and field, to the preferences of candidates
                            </Typography>
                            <Typography paragraph color="secondary" variant="body2">
                                At any time, you can see how many candidates match your job, and
                                once per job you can choose to push a notification to these
                                candidates
                            </Typography>
                            <Typography paragraph color="secondary" variant="body2">
                                These email/in-site notifications will only go to candidates who
                                have selected they want to receive them
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <IconGrid />
                <IconGridSmall />
            </Paper>
        </PageContainer>
    );
};

export default AdvertisePage;
