import React, { useCallback, useEffect, useRef, useState } from "react";

import EventCard from "./EventCard";
import Grid from "@mui/material/Grid";
import { CustomInfiniteScroll as InfiniteScroll } from "../common/CustomInfiniteScroll";
import PageContainer from "../common/PageContainer";
import Typography from "@mui/material/Typography";
import axiosInstance from "../axiosInstance";

const EventListPage = () => {
    const [events, setEvents] = useState([]);
    const nextData = useRef("/api/events/");
    const [hasMore, setHasMore] = useState(false);
    const [totalItems, setTotalItems] = useState(0);

    const fetchMoreData = useCallback(() => {
        axiosInstance.get(nextData.current).then((response) => {
            setEvents((items) => items.concat(response.data.results));
            setTotalItems(response.data.count);

            var next = "";
            if (response.data.next) {
                let next_queries = response.data.next.split("?");
                next = next_queries[next_queries.length - 1];
                nextData.current = "/api/events/?" + next;
                setHasMore(true);
            } else {
                nextData.current = undefined;
                setHasMore(false);
            }
        });
    }, []);

    useEffect(() => {
        fetchMoreData();
    }, [fetchMoreData]);

    return (
        <PageContainer>
            <Grid container>
                <Grid item xs={12}>
                    <Typography
                        variant="subtitle"
                        color="primary"
                        align="center"
                        sx={{
                            display: "block",
                            padding: 0,
                            paddingBottom: "20px",
                            paddingTop: "20px",
                        }}
                    >
                        {totalItems} events
                    </Typography>
                    <InfiniteScroll
                        dataLength={events.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                    >
                        <Grid container spacing={2}>
                            {events.map((event) => (
                                <Grid item sm={12} xs={12} key={event.id}>
                                    <EventCard event={event} />
                                </Grid>
                            ))}
                        </Grid>
                    </InfiniteScroll>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default EventListPage;
