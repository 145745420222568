import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
    getAccountType,
    getUserId,
    isLoggedIn,
    refreshTokens,
    removeTokens,
} from "./auth/functions";

import AboutUsPage from "./v2/AboutUsPage";
import ActivationPage from "./auth/ActivationPage";
import AdvertisePage from "./v2/AdvertisePage";
import ArticleListPage from "./articles/ArticleListPage";
import ArticlePage from "./articles/ArticlePage";
import ContactUsPage from "./v2/ContactUsPage";
import EmailVerificationPage from "./auth/EmailVerificationPage";
import EmployerAdminPage from "./employers/EmployerAdminPage";
import EmployerListPage from "./employers/EmployerListPage";
import EmployerPage from "./employers/EmployerPage";
import EventListPage from "./events/EventListPage";
import EventPage from "./events/EventPage";
import Footer from "./v2/Footer";
import { Helmet } from "react-helmet";
import JobPage from "./jobs/JobPage";
import JobSearchPage from "./jobs/JobSearchPage";
import LandingPage from "./v2/LandingPage";
import LoginPage from "./auth/LoginPage";
import NavBar from "./v2/NavBar";
import NewJobPage from "./jobs/NewJobPage";
import NotFoundPage from "./common/NotFoundPage";
import PageContainer from "./common/PageContainer";
import Paper from "@mui/material/Paper";
import PasswordChangePage from "./auth/PasswordChangePage";
import PasswordResetPage from "./auth/PasswordResetPage";
import PasswordResetRequestPage from "./auth/PasswordResetRequestPage";
import { PrivacyPolicyPage } from "./PrivacyPolicyDialog";
import ProfilePage from "./candidates/ProfilePage";
import ProfileSearchPage from "./candidates/ProfileSearchPage";
import ResourcesPage from "./v2/ResourcesPage";
import ScrollToTop from "./common/ScrollToTop";
import SignupPage from "./auth/SignupPage";
import { SnackbarProvider } from "notistack";
import { TermsAndConditionsPage } from "./TermsAndConditionsDialog";
import WelcomePromptDialog from "./WelcomePromptDialog";
import axiosInstance from "./axiosInstance";

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        type: "light",

        text: {
            primary: "#283E72",
        },
        primary: {
            main: "#1A82c5",
        },
        secondary: {
            main: "#FFFFFF",
        },

        highlight: {
            main: "#EFC100",
            contrastText: "#283E72",
        },
        pink: {
            main: "#EA4C82",
            contrastText: "#FFFFFF",
        },
    },
    typography: {
        h1: {
            fontWeight: 600,
            fontFamily: "Source Sans Pro",
        },
        h2: {
            fontWeight: 600,
            fontFamily: "Source Sans Pro",
        },
        h3: {
            fontWeight: 600,
            fontFamily: "Source Sans Pro",
        },
        h4: {
            fontWeight: 600,
            fontFamily: "Source Sans Pro",
        },
        h5: {
            fontWeight: 600,
            fontFamily: "Source Sans Pro",
        },
        h6: {
            fontWeight: 600,
            fontFamily: "Source Sans Pro",
        },
        fontFamily: "Nunito Sans",
    },
    components: {
        MuiAppBar: {
            defaultProps: {
                color: "primary",
            },
        },
        MuiSkeleton: {
            defaultProps: {
                sx: { bgcolor: "rgba(158, 152, 182, 0.21)" },
            },
        },
        MuiCard: {
            variants: [
                {
                    props: { color: "primary" },
                    style: { backgroundColor: "#283E72" },
                },
                {
                    props: { color: "secondary" },
                    style: { backgroundColor: "#FFFFFF" },
                },
                {
                    props: { color: "disabled" },
                    style: { backgroundColor: "#444c56" },
                },
            ],
        },
        MuiPaper: {
            variants: [
                {
                    props: { color: "primary" },
                    style: { backgroundColor: "#283E72", color: "white" },
                },
            ],
            variants: [
                {
                    props: { noPadding: true },
                    style: { padding: 0 },
                },
            ],
        },
        MuiLink: {
            variants: [
                {
                    props: { color: "text" },
                    style: { color: "#283E72" },
                },
            ],
        },
        MuiFab: {
            variants: [
                {
                    props: { color: "primary" },
                    style: {
                        backgroundColor: "#EFC100",
                        "&:hover": {
                            background: "orange",
                        },
                    },
                },
            ],
        },
    },
});
export const DarkModeContext = React.createContext({ darkMode: false, setDarkMode: () => {} });
function App() {
    let navigate = useNavigate();
    const [userId, setUserId] = useState(getUserId());
    const [bannerNotifications, setBannerNotifications] = useState({});
    const [darkMode, setDarkMode] = useState(false);
    const [welcomePromptOpen, setWelcomePromptOpen] = useState(false);

    const addBannerNotification = useCallback((notification, id) => {
        setBannerNotifications((oldBannerNotifications) => ({
            ...oldBannerNotifications,
            [id]: notification,
        }));
    }, []);

    const [user, setUser] = useState(undefined);
    useEffect(() => {
        if (userId) {
            updateUser();
        }
    }, [userId]);

    const candidateLoggedIn = useMemo(
        () => user && user?.profile && getAccountType() === "candidate",
        [user]
    );

    useEffect(() => {
        if (candidateLoggedIn && user?.profile?.welcome_seen === false) {
            setWelcomePromptOpen(true);
            let formData = new FormData();
            formData.append("profile.welcome_seen", true);
            axiosInstance.patch(`/api/users/${user.id}/`, formData);
        }
    }, [user]);

    const updateUser = () => {
        axiosInstance.get(`/api/auth/users/me/`).then((response) => {
            setUser(response.data);
        });
        window.posthog.identify(userId);
    };

    const logout = () => {
        removeTokens();
        setUser(undefined);
        setUserId(undefined);
        navigate("/");
    };

    useEffect(() => {
        if (!isLoggedIn() && localStorage.getItem("refresh_token")) {
            refreshTokens(() => {
                setUserId(getUserId());
                updateUser();
            });
        }
        if (isLoggedIn() && !userId) {
            setUserId(getUserId());
        }
        if (
            isLoggedIn() &&
            user &&
            ((user?.employer && getAccountType() !== "employer") ||
                (!user?.employer && getAccountType() === "employer"))
        ) {
            logout();
        }
    });
    return (
        <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
                    <Helmet>
                        <title>SpaceCareers.uk</title>
                        <meta
                            property="og:image"
                            content={window.location.origin + "/favicon.png"}
                        />
                    </Helmet>
                    <NavBar
                        user={user}
                        onLogout={logout}
                        bannerNotifications={bannerNotifications}
                    />
                    <WelcomePromptDialog
                        open={welcomePromptOpen}
                        onClose={() => setWelcomePromptOpen(false)}
                        user={user}
                    />
                    <ScrollToTop>
                        <Routes>
                            <Route path="" element={<Navigate to="/home" />} />
                            <Route path="home" element={<LandingPage />} />
                            <Route path="login" element={<LoginPage />} />
                            <Route path="signup/:token" element={<SignupPage />} />
                            <Route path="signup" element={<SignupPage />} />
                            <Route path="activate/:uid/:token" element={<ActivationPage />} />
                            <Route path="verify" element={<EmailVerificationPage />} />
                            <Route path="reset" element={<PasswordResetRequestPage />} />
                            <Route path="change-password" element={<PasswordChangePage />} />
                            <Route path="reset/:uid/:token" element={<PasswordResetPage />} />
                            <Route path="jobs/new" element={<NewJobPage user={user} />} />
                            <Route
                                path="jobs/:jobId"
                                element={
                                    <JobPage
                                        user={user}
                                        addBannerNotification={addBannerNotification}
                                    />
                                }
                            />
                            <Route path="candidates" element={<ProfileSearchPage />} />
                            <Route path="jobs" element={<JobSearchPage user={user} />} />
                            <Route
                                path="events/:eventId"
                                element={
                                    <EventPage addBannerNotification={addBannerNotification} />
                                }
                            />
                            <Route path="events" element={<EventListPage />} />
                            <Route
                                path="employers/:employerId"
                                element={
                                    <EmployerPage
                                        user={user}
                                        addBannerNotification={addBannerNotification}
                                    />
                                }
                            />
                            <Route path="employers/" element={<EmployerListPage />} />
                            <Route path="careers_resources/" element={<ResourcesPage />} />
                            <Route path="articles/" element={<ArticleListPage />} />
                            <Route
                                path="articles/:articleId"
                                element={
                                    <ArticlePage addBannerNotification={addBannerNotification} />
                                }
                            />
                            <Route
                                path="articles/at_school"
                                element={<ArticleListPage parentPage="At School" />}
                            />
                            <Route
                                path="articles/at_university"
                                element={<ArticleListPage parentPage="At University" />}
                            />
                            <Route
                                path="articles/job_profiles"
                                element={<ArticleListPage parentPage="Job Profiles" />}
                            />
                            <Route
                                path="articles/interviews"
                                element={<ArticleListPage parentPage="Interviews" />}
                            />
                            <Route
                                path="articles/career_stories"
                                element={<ArticleListPage parentPage="Career Stories" />}
                            />
                            <Route
                                path="articles/advice_and_information"
                                element={<ArticleListPage parentPage="Advice and Information" />}
                            />
                            <Route
                                path="articles/advice"
                                element={<ArticleListPage parentPage="Advice and Information" />}
                            />
                            <Route
                                path="profile"
                                element={
                                    <ProfilePage
                                        user={user}
                                        updateUser={updateUser}
                                        logout={logout}
                                    />
                                }
                            />
                            <Route path="employers/admin" element={<EmployerAdminPage />} />
                            <Route path="post-a-job" element={<AdvertisePage />} />
                            <Route path="advertise" element={<AdvertisePage />} />
                            <Route path="contact-us" element={<ContactUsPage />} />
                            <Route path="about" element={<AboutUsPage />} />
                            <Route path="privacy" element={<PrivacyPolicyPage />} />
                            <Route path="terms" element={<TermsAndConditionsPage />} />
                            <Route
                                path="relaunch"
                                element={
                                    <PageContainer>
                                        <Paper>
                                            <iframe
                                                src="https://docs.google.com/document/d/e/2PACX-1vQ5NiJLaQmCLQgj4tP0cHzQ5vHgltSkTj7nTQ08qBNJE0BWmdoHtQxuBbbmKmHSrhSGDGdPUuE2FQG7/pub?embedded=true"
                                                style={{
                                                    width: "100%",
                                                    height: "80vh",
                                                    border: "none",
                                                }}
                                            />
                                        </Paper>
                                    </PageContainer>
                                }
                            />
                            <Route path="/404" element={<NotFoundPage />} />
                            <Route path="*" element={<Navigate to="/404" replace />} />
                        </Routes>
                    </ScrollToTop>
                    <Footer />
                </SnackbarProvider>
            </ThemeProvider>
        </DarkModeContext.Provider>
    );
}

export default App;
